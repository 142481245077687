import Api from "./api"

export default {
  /**
   * Return list of purchases
   */
  fetch() {
    return Api().get("purchases/")
  },

  /**
   * Return a single purchase  by id
   * @param {Number} id the purchase id
   */
  fetchOne(id) {
    return Api().get(`purchases/${id}/`)
  },

  /**
   * Send a post request to the purchasea endpoint
   * @param {Object} payload a purchase object
   */
  create(payload) {
    return Api().post("purchases/", payload)
  },

  /**
   * Send a put request to the purchases endpoint
   * @param {Number} id the purchase id
   * @param {Object} payload a purchase object
   */
  update(id, payload) {
    return Api().put(`purchases/${id}/`, payload)
  },

  /**
   * Send a delete request to the purchases endpoint
   * @param {Number} id the purchase id
   */
  delete(id) {
    return Api().delete(`purchases/${id}/`)
  },
}
