import VueRouter from "vue-router"

import Vendors from "@/pages/Vendors.vue"
import PurchaseTypes from "@/pages/PurchaseTypes.vue"
import LineItems from "@/pages/LineItems.vue"
import Purchases from "@/pages/Purchases.vue"

// Assign views to routes here
const routes = [
  { path: "/", redirect: "/purchases" },
  { path: "/purchases", component: Purchases, name: "purchases" },
  { path: "/vendors", component: Vendors, name: "vendors" },
  {
    path: "/purchase-types",
    component: PurchaseTypes,
    name: "purchase-types",
  },
  { path: "/line-items", component: LineItems, name: "line-items" },
]

const router = new VueRouter({
  routes,
})

export default router
