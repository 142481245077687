<template>
  <div class="card">
    <div class="card-block">
      <div class="panel-flex">
        <div
          v-for="(filter, index) in filters"
          :key="index"
          class="item-flex py-1"
        >
          {{ filter.label }}
          <multi-select
            :id="`${filter.name}-select`"
            ref="filterSelect"
            v-model="filter.selected"
            class="py-1"
            mode="tags"
            :searchable="true"
            :create-tag="true"
            :close-on-select="true"
            :clear-on-select="true"
            :options="filter.options.filter((option) => option.value.length)"
            value-prop="value"
            track-by="value"
            label="text"
            placeholder="Select a filter..."
            data-cy="filterOption"
          >
            <template #tag="{ option, handleTagRemove, disabled }">
              <div class="multiselect-tag bg-primary">
                {{ option.text }}
                <i
                  v-if="!disabled"
                  @click.prevent
                  @mousedown.prevent.stop="handleTagRemove(option, $event)"
                />
              </div>
            </template>
          </multi-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from "@vueform/multiselect/dist/multiselect.vue2.js"
import { format } from "date-fns"
// import MultiSelect from "@vueform/multiselect" for Vue 3
export default {
  components: {
    MultiSelect,
  },
  props: {
    gridApi: {
      type: Object,
      required: true,
    },
    rowData: {
      type: Array,
      required: true,
    },
    columnDefs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filters: [],
    }
  },
  watch: {
    filters: {
      handler: function (filters) {
        for (let filter of filters) {
          const filterInstance = this.gridApi.getFilterInstance(filter.name)
          if (filter.selected && filter.selected.length) {
            // Account for 'none' needing to be null for the grid to understand
            const model = filter.selected.map((o) => {
              if (o === "none") {
                return null
              } else if (o === "Yes") {
                return "true"
              } else if (o === "No") {
                return "false"
              } else {
                return o
              }
            })

            filterInstance.setModel({
              type: "set",
              values: model,
            })
          } else {
            filterInstance.selectEverything()
          }
        }
        // Apply all filters
        this.gridApi.onFilterChanged()
      },
      deep: true,
    },
  },
  methods: {
    /**
     * Build the options to show in the multiselects
     * @returns {Array} mapped array of options suitable for the multiple select component
     */
    getColumnOptions(name) {
      const options = new Set([])
      const rows = this.rowData || []

      // Build the initial options array
      rows.forEach((row) => {
        options.add(row[name])
      })

      // Remap them to be usable by the multi select
      return Array.from(options).map((option) => {
        // Allow filtering of null by making it a selectable string: `none`
        let value = option
        if (option === null) {
          value = "none"
          option = "none"
        } else if (typeof option === "boolean") {
          option = option ? "Yes" : "No"
        } else if (
          name !== "purchase_date" &&
          (name.endsWith("_date") || name.startsWith("date_"))
        ) {
          option = format(new Date(option), "M-d-yyyy")
        } else if (name === "modified") {
          option = format(new Date(option), "M-d-yyyy, h:mm a")
        }
        const optionModel = {
          value: value.toString(),
          text: option.toString(),
        }
        return optionModel
      })
    },
    /**
     * Build the filters object so that the pane can work on the gridOptions
     */
    buildFilters() {
      // Fetch pre-existing filters from the grid
      const initialModel = this.gridApi.getFilterModel()
      // Account for a null by converting to a string: `none`
      for (let pf in initialModel) {
        initialModel[pf].values = Array.from(initialModel[pf].values).map(
          (v) => {
            if (v === null) {
              return "none"
            } else if (v === "true") {
              return "Yes"
            } else if (v === "false") {
              return "No"
            } else {
              return v
            }
          },
        )
      }

      // Build our filters data
      const newFilters = this.columnDefs.map((col) => {
        // Set up general information
        const column = {
          name: col.field,
          label: col.headerName,
          options: [],
          selected: [],
        }

        // Collect options to be shown
        column.options = this.getColumnOptions(column.name)

        // See if this column has a pre-existing filter, and add that to our model if it does
        if (initialModel[col.field]) {
          column.selected = Array.from(initialModel[col.field].values)
        }

        return column
      })
      this.filters = newFilters
      // Apply all filters
    },
    /**
     * Prepare the filters for use, intended for easy use from outside of this component.
     */
    init() {
      this.buildFilters()
    },
    /**
     * Clear all filters
     *
     * Intended for easy use from outside of this component to clear ag-grid's filtering and make
     * sure this component stays informed at the same time.
     */
    clearAll() {
      this.filters.forEach((f) => (f.selected = []))
    },
  },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="postcss" scoped>
.panel-flex {
  display: flex;
  flex: auto;
  flex-direction: column;
}
div.multiselect-tag i::before {
  color: #ccc;
}
</style>
