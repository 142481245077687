<template>
  <div class="card">
    <div class="card-block panel-scroll" data-cy="columnsPanel">
      <div
        v-for="(column, index) in columns"
        :id="column.colId"
        :key="index"
        class="dropdown-item item px-2"
        @click="toggleColumn(column)"
      >
        <input type="checkbox" :name="column.colId" :checked="column.visible" />
        <span class="ml-2">{{ column.colDef.headerName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columnApi: { required: true, type: Object },
  },
  data() {
    return {
      columns: this.columnApi.getAllColumns(),
    }
  },
  methods: {
    toggleColumn(col) {
      this.columnApi.setColumnVisible(col.colId, !col.visible)
    },
  },
}
</script>
