<template>
  <icon-button
    v-if="!open"
    icon="trash-2"
    type="accent"
    outline
    data-cy="deleteLineitem"
    label="Delete"
    @click="open = true"
  />
  <div
    v-else
    class="card delete-confirm delete-dialog"
    style="margin-top: 0.125em"
  >
    <div class="card-block p-3">
      <p class="text-danger">
        Are you sure you want to permanantly delete this line item?
      </p>
      <div class="d-flex justify-content-end">
        <icon-button
          label="Cancel"
          type="accent"
          outline
          icon="x"
          data-cy="cancelLineitemConfirm"
          @click="open = false"
        />
        <icon-button
          label="Delete"
          type="accent"
          class="ml-1"
          icon="trash-2"
          outline
          data-cy="deleteLineitemConfirm"
          @click="deleteLineItem"
        />
      </div>
    </div>
  </div>
</template>
<script>
import IconButton from "./IconButton"

export default {
  components: {
    IconButton,
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    deleteLineItem() {
      this.open = false
      this.$emit("input")
    },
  },
}
</script>

<style lang="postcss" scoped>
div.delete-dialog {
  position: absolute;
  z-index: 2;
  right: 10px;
  bottom: 10px;
  width: 400px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  border-radius: 0.25rem;
}
</style>
