<!-- Usage:
    Line Item form receives a uuid of a line item to identify the form. It uses the id to fetch itself
    from the current purchase. It will update it's own total as values in the form change. It will figure 
    out it's own errors based on the uuid. It also handles switching to the Purchase Types add form within 
    the purchases modal (the @active event) which needs to be handled by the Purchases modal.

    <line-item-form :id="<the line item guid>" @active="setActiveLI">
    </line-item-form>

-->

<template>
  <div :id="'lineItemForm-' + id">
    <div class="row">
      <input-box
        :id="'account-' + id"
        v-model="value.account"
        maska="#######"
        type="text"
        class="col-sm-3"
        label="Account"
        placeholder="XXXXXXX"
        :danger="!!errors.account"
        :danger-message="errors.account"
        data-cy="accountField"
      >
      </input-box>
      <input-box
        :id="'objectCode-' + id"
        v-model="value.object_code"
        maska="XXXX"
        type="text"
        class="col-sm-3"
        label="Object Code"
        placeholder="XXXX"
        :danger="!!errors.object_code"
        :danger-message="errors.object_code"
        data-cy="objectCodeField"
      >
      </input-box>
      <div class="d-flex flex-row col-6">
        <single-select
          :id="'purchase-type-' + id"
          v-model="value.purchase_type"
          label="Purchase Type"
          :options="purchaseTypes"
          :danger="!!errors.purchase_type"
          :danger-message="errors.purchase_type"
          data-cy="purchaseTypeField"
        />
        <icon-button
          class="create-button"
          type="secondary"
          outline
          icon="plus"
          data-cy="createButton"
          @click="openPurchaseTypeForm"
        />
      </div>
    </div>

    <div class="row">
      <input-box
        :id="'quantity-' + id"
        v-model="value.quantity"
        type="number"
        class="col-sm-3"
        label="Quantity"
        placeholder="0"
        :danger="!!errors.quantity"
        :danger-message="errors.quantity"
        data-cy="quantityField"
      >
      </input-box>
      <input-box
        :id="'unitCost-' + id"
        v-model="value.unit_cost"
        maska="#*.##"
        type="text"
        class="col-sm-3"
        label="Unit Cost"
        placeholder="0.00"
        addon="start"
        :danger="!!errors.unit_cost"
        :danger-message="errors.unit_cost"
        data-cy="unitCostField"
      >
        <div slot="addon-start">$</div>
      </input-box>
      <input-box
        :id="'shippingAmount-' + id"
        v-model="value.shipping_amount"
        maska="#*.##"
        type="text"
        class="col-sm-3"
        label="Shipping Amount"
        placeholder="0.00"
        addon="start"
        :danger="!!errors.shipping_amount"
        :danger-message="errors.shipping_amount"
        data-cy="shippingAmountField"
      >
        <div slot="addon-start">$</div>
      </input-box>
      <input-box
        :id="'tax-' + id"
        v-model="value.tax"
        maska="#*.##"
        type="text"
        class="col-sm-3"
        label="Tax"
        placeholder="0.00"
        addon="start"
        :danger="!!errors.tax"
        :danger-message="errors.tax"
        data-cy="taxField"
      >
        <div slot="addon-start">$</div>
      </input-box>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import InputBox from "./InputBox"
import IconButton from "./IconButton"
import SingleSelect from "./SingleSelect"

export default {
  components: {
    InputBox,
    IconButton,
    SingleSelect,
  },
  props: {
    id: {
      validator: function (value) {
        // Either a string or undefined (since line items are removed from
        // the state, the modal is still open, producing errors since
        // undefined is not a string) ¯\_(ツ)_/¯
        if (typeof value === "string" || value === undefined) {
          return true
        }
        return false
      },
      required: true,
    },
  },
  data() {
    return {
      // Store the available purchase types for the dropdown
      purchaseTypes: [],
      // Used by the errors and item for tracking position in the purchases
      // lineitem_purchase array
      index: undefined,
    }
  },
  computed: {
    ...mapState("purchases", {
      // The Line Item for this form
      value({ activeItem }) {
        let result = {}
        for (const index in activeItem.lineitem_purchase) {
          if (activeItem.lineitem_purchase[index].uuid === this.id) {
            this.index = index
            result = activeItem.lineitem_purchase[index]
          }
        }
        return result
      },
      // Errors associated with this Line Item
      errors({ activeItem, errors }) {
        let result = {}
        for (const index in activeItem.lineitem_purchase) {
          if (activeItem.lineitem_purchase[index].uuid === this.id) {
            if (errors.lineitem_purchase[index]) {
              result = errors.lineitem_purchase[index]
            }
          }
        }
        return result
      },
    }),
    ...mapGetters("purchaseTypes", {
      ptItems: "items",
    }),
    total: function () {
      const item = this.value
      let cost = parseFloat(item.unit_cost) * parseInt(item.quantity)
      let tax = parseFloat(item.tax)
      let ship = parseFloat(item.shipping_amount)
      if (isNaN(cost)) {
        cost = 0
      }
      if (isNaN(tax)) {
        tax = 0
      }
      if (isNaN(ship)) {
        ship = 0
      }
      return (cost + tax + ship).toFixed(2)
    },
  },
  watch: {
    total: function (value) {
      this.value.line_item_total = value
    },
    value: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.updateLI(val, this.index)
      },
    },
  },
  mounted() {
    this.fetchPurchaseTypes()
      .then(() => {
        return this.ptItems.filter((p) => p.active)
      })
      .then((items) => {
        this.purchaseTypes = items.map((v) => ({
          value: v.name,
          text: v.name,
        }))
      })
  },
  methods: {
    ...mapActions("purchaseTypes", {
      fetchPurchaseTypes: "fetchAll",
      clearPurchaseType: "clearItem",
    }),
    ...mapActions("purchases", ["setShowPurchaseTypeForm", "updateLI"]),
    openPurchaseTypeForm() {
      this.setShowPurchaseTypeForm(true)
      this.$emit("active", this.id)
      this.clearPurchaseType()
    },
  },
}
</script>

<style lang="postcss" scoped>
.create-button {
  height: 38px;
  margin-top: 2em;
  margin-left: 0.25em;
}
.select-flex {
  padding: 0px;
  max-width: 84%;
  flex: 1 0 auto;
}
</style>
