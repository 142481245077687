<template>
  <div class="d-flex flex-column">
    <div data-cy="purchaseTypesForm">
      <input-box
        id="nameForm"
        v-model="item.name"
        type="text"
        label="Name"
        :danger="!!errors.name"
        :danger-message="errors.name"
        data-cy="namePurchaseTypesForm"
      >
      </input-box>
    </div>
    <div>
      <div>Active</div>
      <div class="d-flex">
        <input
          id="activeForm"
          v-model="item.active"
          type="checkbox"
          class="form-check-input ml-0"
          data-cy="activePurchaseTypesForm"
        />
        <label class="form-check-label ml-4" for="activeForm">{{
          item.active ? "Yes" : "No"
        }}</label>
      </div>
    </div>
    <div v-if="errors.server" class="mt-4">
      <alert
        v-if="errors.server"
        id="alert-purchaseTypes"
        type="danger"
        :message="errors.server"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Alert from "./Alert"
import InputBox from "./InputBox"

export default {
  components: {
    InputBox,
    Alert,
  },
  computed: {
    ...mapGetters("purchaseTypes", {
      item: "activeItem",
      errors: "errors",
    }),
  },
}
</script>
