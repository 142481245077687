<template>
  <div class="d-flex flex-column">
    <input-box
      id="nameForm"
      v-model="item.name"
      type="text"
      label="Name"
      :danger="!!errors.name"
      :danger-message="errors.name"
      data-cy="nameForm"
    />
    <input-box
      id="websiteForm"
      v-model="item.website"
      type="text"
      label="Website"
      placeholder="www.website.com"
      :danger="!!errors.website"
      :danger-message="errors.website"
      data-cy="websiteForm"
    />
    <input-box
      id="phoneNumberForm"
      v-model="item.phone_number"
      :maska="['###-###-####', '###-###-#### ext ####']"
      type="text"
      label="Phone Number"
      placeholder="XXX-XXX-XXXX"
      :danger="!!errors.phone_number"
      :danger-message="errors.phone_number"
      data-cy="phoneNumberForm"
    />
    <input-box
      id="emailForm"
      v-model="item.email"
      type="text"
      label="Email"
      placeholder="user@domain.com"
      :danger="!!errors.email"
      :danger-message="errors.email"
      data-cy="emailForm"
    />
    <input-box
      id="contactForm"
      v-model="item.contact_name"
      type="text"
      label="Contact"
      placeholder="First Last"
      :danger="!!errors.contact_name"
      :danger-message="errors.contact_name"
      data-cy="contactForm"
    />
    <div class="py-1">
      <div>Active</div>
      <div class="d-flex">
        <input
          id="activeForm"
          v-model="item.active"
          type="checkbox"
          class="form-check-input ml-0"
          data-cy="activePurchaseTypesForm"
        />
        <label class="form-check-label ml-4" for="activeForm">{{
          item.active ? "Yes" : "No"
        }}</label>
      </div>
    </div>
    <div class="form-group mt-2" :class="[{ 'has-danger': !!errors.notes }]">
      <label id="notesForm-label" for="notesForm" aria-label="Notes">
        Notes
      </label>
      <div class="input-group">
        <textarea
          id="notesForm"
          v-model="item.notes"
          class="form-control"
          :class="[
            {
              'form-control-danger': !!errors.notes,
            },
          ]"
          aria-describedby="notesForm-label"
          data-cy="notesForm"
        />
        <div v-if="!!errors.notes" class="form-control-feedback">
          {{ errors.notes }}
        </div>
      </div>
    </div>
    <div v-if="errors.server">
      <alert
        v-if="errors.server"
        id="alert-vendors"
        type="danger"
        :message="errors.server"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import InputBox from "./InputBox"
import Alert from "./Alert"

export default {
  components: {
    InputBox,
    Alert,
  },
  computed: {
    ...mapGetters("vendors", {
      item: "activeItem",
      errors: "errors",
    }),
  },
  methods: {
    updateActive(newValue) {
      this.item.active = newValue
    },
  },
}
</script>
