<template>
  <div class="form-group" :class="[{ 'has-danger': danger }]">
    <label :id="`${id}-label`" :for="id" :aria-label="label">
      {{ label }}
    </label>
    <select
      :id="id"
      :value="value"
      class="form-control custom-select"
      :class="[
        {
          'form-control-danger': danger,
          'normal-x': danger,
        },
      ]"
      :aria-describedby="`${id}-label`"
      @input="$emit('input', $event.target.value)"
    >
      <option class="select-default" value="">Select an option...</option>
      <option
        v-for="(option, index) in options"
        :key="index"
        class="singleselect__option"
        :value="option.value"
        :selected="option.value === value"
      >
        {{ option.text }}
      </option>
    </select>
    <div v-if="danger" class="form-control-feedback">
      {{ dangerMessage }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    dangerMessage: {
      type: String,
      default: "",
    },
  },
  computed: {
    danger() {
      return this.dangerMessage.length
    },
  },
}
</script>
<style lang="postcss" scoped>
.normal-x {
  background-size: 18px 22px;
}
.select-default {
  display: none;
}
</style>
