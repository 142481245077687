import Api from "./api"

export default {
  /**
   * Return list of purchase types
   */
  fetch() {
    return Api().get("purchase-types/")
  },

  /**
   * Return a single purchase type by id
   * @param {Number} id the purchase type id
   */
  fetchOne(id) {
    return Api().get(`purchase-types/${id}/`)
  },

  /**
   * Send a post request to the purchase type endpoint
   * @param {Object} payload a purchase type object
   */
  create(payload) {
    return Api().post("purchase-types/", payload)
  },

  /**
   * Send a put request to the purchase type endpoint
   * @param {Number} id the purchase type id
   * @param {Object} payload a purchase type object
   */
  update(id, payload) {
    return Api().put(`purchase-types/${id}/`, payload)
  },

  /**
   * Send a delete request to the purchase type endpoint
   * @param {Number} id the purchase type id
   */
  delete(id) {
    return Api().delete(`purchase-types/${id}/`)
  },
}
