import { ref, watch } from "@vue/composition-api"

/**
 * @typedef {Object} UseGridReturn
 * @property {Object} modal
 * @property {Object} gridApi
 * @property {Object} columnApi
 * @property {Object} gridOptions
 * @property {string} quickFilter
 * @property {boolean} showColumnSelect
 * @property {boolean} showFilters
 * @property {boolean} selectionExists
 * @property {Function} setGridDefaults
 * @property {Function} showDefaultColumns
 * @property {Function} showAllColumns
 * @property {Function} toggleFilterPanel
 * @property {Function} toggleColumnSelectPanel
 */

/**
 * Returns a grid options object & helpers for interacting with the grid.
 * @param {Object} gridConfig should include `colDefs` and any option overrides
 * @param {Object} gridDefaults should include `sort` and `filter` defaults for the grid
 * @returns {UseGridReturn}
 */
export default function useGrid(gridConfig, gridDefaults) {
  const modal = ref({ type: null, action: null })
  const gridApi = ref(null)
  const columnApi = ref(null)
  const quickFilter = ref("")
  const showColumnSelect = ref(false)
  const showFilters = ref(false)
  const selectionExists = ref(false)

  const gridOptions = ref({
    animateRows: true,
    enableFilter: false,
    enableRangeSelection: true,
    rowSelection: "single",
    noRowsOverlayComponent: "noRowsOverlayComponent",
    defaultColDef: {
      sortable: true,
      resizable: true,
    },
    paginationAutoPageSize: true,
    getContextMenuItems() {
      return ["copy", "copyWithHeaders"]
    },
    onGridReady(params) {
      gridApi.value = params.api
      columnApi.value = params.columnApi
    },
    onSelectionChanged({ api }) {
      selectionExists.value = api.getSelectedRows().length > 0
    },
    onModelUpdated({ api, columnApi }) {
      columnApi.autoSizeColumns(columnApi.getAllColumns())
      if (!api.getDisplayedRowCount()) {
        api.showNoRowsOverlay()
      } else {
        api.hideOverlay()
      }
    },
    frameworkComponents: {
      // imported, registered to the app in main.js
      dateTimeCellRenderer: "DateTimeCellRenderer",
      dateCellRenderer: "DateCellRenderer",
      booleanCellRenderer: "BooleanCellRenderer",
      currencyCellRenderer: "CurrencyCellRenderer",
      noRowsOverlayComponent: "NoRowsOverlay",
    },
    ...gridConfig,
  })

  watch(quickFilter, (newValue, _oldValue) => {
    gridApi.value.setQuickFilter(newValue)
  })

  function toggleFilterPanel() {
    showFilters.value = !showFilters.value
  }

  function toggleColumnSelectPanel() {
    showColumnSelect.value = !showColumnSelect.value
  }

  function showDefaultColumns() {
    columnApi.value.getAllColumns().forEach((col) => {
      columnApi.value.setColumnVisible(col.colId, !col.colDef.hide)
    })
  }

  function showAllColumns() {
    columnApi.value.getAllColumns().forEach((col) => {
      columnApi.value.setColumnVisible(col.colId, true)
    })
  }

  function setGridDefaults() {
    if (gridApi.value) {
      // Apply sorting
      if (gridDefaults.sort) {
        gridApi.value.setSortModel(gridDefaults.sort)
      }
      // Apply filtering
      if (gridDefaults.filter) {
        gridApi.value.setFilterModel(gridDefaults.filter)
      }
    }
  }

  return {
    modal,
    gridApi,
    columnApi,
    gridOptions,
    quickFilter,
    selectionExists,
    showColumnSelect,
    showFilters,
    setGridDefaults,
    showDefaultColumns,
    showAllColumns,
    toggleFilterPanel,
    toggleColumnSelectPanel,
  }
}
