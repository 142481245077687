<template>
  <p>{{ humanize }}</p>
</template>

<script>
import { format } from "date-fns"
/**
 * A cell renderer for ag-grid to render dates
 */
export default {
  name: "DateCellRenderer",
  computed: {
    humanize() {
      if (this.params.value !== null) {
        return format(new Date(this.params.value), "M-d-yyyy")
      } else {
        return ""
      }
    },
  },
}
</script>
