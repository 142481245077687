<template>
  <div class="row">
    <input-box
      id="ipurchase_description"
      v-model="item.purchase_description"
      class="col-sm-6"
      type="text"
      label="Purchase Description"
      :danger="!!errors.purchase_description"
      :danger-message="errors.purchase_description"
    />

    <input-box
      id="iedoc_number"
      v-model="item.edoc_number"
      maska="########"
      class="col-sm-6"
      type="text"
      label="Edoc Number"
      placeholder="XXXXXXX"
      :danger="!!errors.edoc_number"
      :danger-message="errors.edoc_number"
    />

    <input-box
      id="icontact_name"
      v-model="item.fso_contact_name"
      class="col-sm-6"
      type="text"
      label="FSO Contact Name"
      placeholder="First Last"
      :danger="!!errors.fso_contact_name"
      :danger-message="errors.fso_contact_name"
    />

    <input-box
      id="icontact_unit"
      v-model="item.fso_contact_unit"
      class="col-sm-6"
      type="text"
      label="FSO Contact Unit"
      :danger="!!errors.fso_contact_unit"
      :danger-message="errors.fso_contact_unit"
    />

    <input-box
      id="ipurchase_date"
      v-model="item.purchase_date"
      class="col-sm-4"
      type="date"
      label="Purchase Date"
      placeholder="mm/dd/yyyy"
      :danger="!!errors.purchase_date"
      :danger-message="errors.purchase_date"
    />

    <input-box
      id="iexpiration_date"
      v-model="item.expiration_date"
      class="col-sm-4"
      type="date"
      label="Expiration Date"
      placeholder="mm/dd/yyyy"
      :danger="!!errors.expiration_date"
      :danger-message="errors.expiration_date"
    />

    <input-box
      id="idate_renewed"
      v-model="item.date_renewed"
      class="col-sm-4"
      type="date"
      label="Date Renewed"
      placeholder="mm/dd/yyyy"
      :danger="!!errors.date_renewed"
      :danger-message="errors.date_renewed"
    />

    <input-box
      id="istatus"
      v-model="item.status"
      class="col-sm-6"
      type="text"
      label="Status"
      :danger="!!errors.status"
      :danger-message="errors.status"
      data-cy="statusPurchaseForm"
    />

    <div class="col-sm-6 d-flex flex-row">
      <single-select
        id="ivendor"
        v-model="item.vendor"
        label="Vendor"
        data-cy="vendorSelect"
        :options="vendors"
        :danger-message="errors.vendor"
      />
      <icon-button
        id="vendorAdd"
        class="create-button"
        type="secondary"
        outline
        icon="plus"
        data-cy="createVendor"
        @click="openVendorForm"
      />
    </div>
    <div class="col-12">
      <div class="form-group" :class="[{ 'has-danger': !!errors.notes }]">
        <label id="inotes-label" for="inotes" aria-label="Notes"> Notes </label>
        <div class="input-group">
          <textarea
            id="inotes"
            v-model="item.notes"
            class="form-control"
            :class="[
              {
                'form-control-danger': !!errors.notes,
              },
            ]"
            aria-describedby="inotes-label"
            data-cy="notesPurchaseForm"
          />
          <div v-if="!!errors.notes" class="form-control-feedback">
            {{ errors.notes }}
          </div>
        </div>
      </div>
    </div>
    <div id="lierror" class="col-sm-12">
      <p>
        <b>{{ errors.non_field_errors }}</b>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import InputBox from "./InputBox"
import IconButton from "./IconButton"
import SingleSelect from "./SingleSelect"

export default {
  components: {
    InputBox,
    IconButton,
    SingleSelect,
  },
  data() {
    return {
      vendors: [],
    }
  },
  computed: {
    ...mapGetters("purchases", {
      item: "activeItem",
      errors: "errors",
    }),
    ...mapGetters("vendors", {
      vendorsItems: "items",
    }),
  },
  created() {
    this.fetchVendors().then(() => {
      this.vendors = this.vendorsItems.map((v) => ({
        value: v.name,
        text: v.name,
      }))
    })
  },
  methods: {
    ...mapActions("vendors", {
      fetchVendors: "fetchAll",
      clearVendor: "clearItem",
    }),
    ...mapActions("purchases", ["setShowVendorForm"]),
    openVendorForm() {
      this.setShowVendorForm(true)
      this.clearVendor()
    },
  },
}
</script>

<style lang="postcss">
input[type="date"] {
  max-height: 38px;
}

.create-button {
  height: 38px;
  margin-top: 2em;
  margin-left: 0.25em;
}

#lierror {
  color: red;
}
</style>
