import { authHeaderInterceptor } from "@uarizona-fnsv/vue-doggo"
import axios from "axios"

axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.xsrfHeaderName = "X-CSRFToken"

export default () => {
  const api = axios.create({
    baseURL: `/api/`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
  api.interceptors.request.use(authHeaderInterceptor)
  return api
}
