import Api from "./api"

export default {
  /**
   * Return list of line items types
   */
  fetch() {
    return Api().get("lineitems/")
  },

  /**
   * Return a single line items type by id
   * @param {Number} id the line items type id
   */
  fetchOne(id) {
    return Api().get(`lineitems/${id}/`)
  },

  /**
   * Send a post request to the line items type endpoint
   * @param {Object} payload a line items type object
   */
  create(payload) {
    return Api().post("lineitems/", payload)
  },

  /**
   * Send a put request to the line items type endpoint
   * @param {Number} id the line items type id
   * @param {Object} payload a line items type object
   */
  update(id, payload) {
    return Api().put(`lineitems/${id}/`, payload)
  },

  /**
   * Send a delete request to the line items type endpoint
   * @param {Number} id the line items type id
   */
  delete(id) {
    return Api().delete(`lineitems/${id}/`)
  },
}
