<template>
  <component
    :is="iconComponent"
    role="img"
    class="inline-block fill-current"
    style="height: 1em; width: 1em"
  />
</template>
<script>
const iconNames = [
  "alert-circle",
  "arrow-up",
  "check-circle",
  "chevron-down",
  "chevron-up",
  "chevrons-up-down",
  "columns",
  "cross",
  "eye",
  "file-x",
  "filter",
  "info",
  "layout-grid",
  "maximize",
  "pencil",
  "plus-circle",
  "plus",
  "refresh-ccw",
  "repeat",
  "save",
  "search",
  "trash-2",
  "wind",
  "x-circle",
  "x",
]
const icons = iconNames.reduce((yielded, iconName) => {
  return { [iconName]: require(`@/assets/icons/${iconName}.svg`), ...yielded }
}, {})

export default {
  props: {
    icon: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      },
    },
  },
  computed: {
    iconComponent() {
      return icons[this.icon]
    },
  },
}
</script>
