<template>
  <div class="card">
    <div class="card-header d-flex justify-content-start align-items-center">
      <h5>{{ title }}</h5>
      <div v-if="collapsedState === true" class="ml-2">
        <slot name="summary"></slot>
      </div>
      <div class="ml-auto">
        <icon-button
          type="secondary"
          outline
          class="px-1 mx-1"
          :icon="collapsedState ? 'chevron-down' : 'chevron-up'"
          @click="toggleCollapse"
        />
      </div>
    </div>
    <div v-show="!collapsedState" class="card-body">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import IconButton from "./IconButton"
export default {
  components: {
    IconButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      collapsedState: false,
    }
  },
  watch: {
    collapsed(collapse) {
      if (collapse !== undefined) {
        this.collapsedState = collapse
      }
    },
  },
  created() {
    this.collapsedState = this.collapsed
  },
  methods: {
    toggleCollapse() {
      this.collapsedState = !this.collapsedState
    },
  },
}
</script>

<style lang="postcss" scoped>
.card-header h5 {
  margin-bottom: 0;
}
</style>
