import Vue from "vue"
import VueCompositionAPI from "@vue/composition-api"
import App from "./App.vue"
import VueResource from "vue-resource"
import VueRouter from "vue-router"
import { sync } from "vuex-router-sync"
import Vue2Filters from "vue2-filters"
import Maska from "maska"
import Doggo from "@uarizona-fnsv/vue-doggo"
import * as Sentry from "@sentry/vue"
import "@uarizona-fnsv/bootstrap/dist/bootstrap.css"

import router from "@/router"
import store from "@/store"
import "ag-grid-enterprise"
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-balham.css"
import { LicenseManager } from "ag-grid-enterprise"

import {
  DateCellRenderer,
  DateTimeCellRenderer,
  BooleanCellRenderer,
  CurrencyCellRenderer,
  NoRowsOverlay,
} from "@/components/grid-framework-components"

// for @vueform/multiselect; can be removed on upgrade to Vue 3
Vue.use(VueCompositionAPI)

// Lets use everything so that vue can use it. Yes.
Vue.use(VueResource)
Vue.use(VueRouter)
Vue.use(Vue2Filters)

Vue.use(Doggo, {
  amplify: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    domain: process.env.VUE_APP_COGNITO_DOMAIN,
    redirectSignIn: process.env.VUE_APP_COGNITO_SIGN_IN_URL,
  },
  disable: process.env.VUE_APP_DISABLE_AUTH,
})
Vue.use(Maska)

// Sync up the router as a vuex module
sync(store, router)

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/renewit-dev\.fnsv\.arizona\.edu/,
      /^https:\/\/renewit\.fnsv\.arizona\.edu/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

// Set the ag-grid license key
LicenseManager.setLicenseKey(
  "University_of_Arizona_Financial_Services_Office_MultiApp_3Devs21_September_2019__MTU2OTAyMDQwMDAwMA==c4380c2e713cbc8bf36a69bb01479b60",
)

Vue.config.productionTip = false

// Globally register cell renderer components
Vue.component("DateCellRenderer", DateCellRenderer)
Vue.component("DateTimeCellRenderer", DateTimeCellRenderer)
Vue.component("BooleanCellRenderer", BooleanCellRenderer)
Vue.component("CurrencyCellRenderer", CurrencyCellRenderer)
Vue.component("NoRowsOverlay", NoRowsOverlay)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
