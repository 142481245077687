import VendorsService from "../services/VendorsService"
import handleErrors from "../errorHelper"

const state = {
  items: [],
  activeItem: {
    name: "",
    notes: "",
    website: "",
    phone_number: "",
    email: "",
    contact_name: "",
    active: true,
  },
  errors: {},
}

const reset = {
  name: "",
  notes: "",
  website: "",
  phone_number: "",
  email: "",
  contact_name: "",
  active: true,
}

const mutations = {
  SET_ITEM(state, payload) {
    state.activeItem = {
      ...payload,
    }
  },
  CLEAR_ITEM(state) {
    const id = state.activeItem.id
    state.activeItem = {
      // preserve ID on clear
      id,
      ...reset,
    }
  },
  SET_ITEMS(state, payload) {
    state.items = payload
  },
  SET_ERRORS(state, errors) {
    state.errors = errors
  },
  CLEAR_ERRORS(state) {
    state.errors = {}
  },
}

const actions = {
  setItem({ commit }, payload) {
    commit("SET_ITEM", payload)
    commit("CLEAR_ERRORS")
  },
  clearItem({ commit }) {
    commit("CLEAR_ITEM")
    commit("CLEAR_ERRORS")
  },
  // For external use of the api, not in relation to the store
  // eslint-disable-next-line no-unused-vars
  async fetchOne({ commit }, id) {
    try {
      const response = await VendorsService.fetchOne(id)
      return response
    } catch (error) {
      return "get failed:" + error.status + " - " + error.statusText
    }
  },
  async fetchAll({ commit }) {
    try {
      const response = await VendorsService.fetch()
      commit("SET_ITEMS", response.data)
    } catch (error) {
      return "get failed:" + error.status + " - " + error.statusText
    }
  },
  async put({ commit, state }) {
    try {
      const response = await VendorsService.update(
        state.activeItem.id,
        state.activeItem,
      )
      commit("CLEAR_ERRORS")
      return response
    } catch (error) {
      handleErrors(commit, error)
      return null
    }
  },
  async post({ commit, state }) {
    try {
      const response = await VendorsService.create(state.activeItem)
      commit("CLEAR_ERRORS")
      return response
    } catch (error) {
      handleErrors(commit, error)
      return null
    }
  },
  async delete({ commit, state }) {
    try {
      const response = await VendorsService.delete(state.activeItem.id)
      commit("CLEAR_ERRORS")
      return response
    } catch (error) {
      handleErrors(commit, error)
      return null
    }
  },
}

const getters = {
  items: (state) => state.items,
  activeItem: (state) => state.activeItem,
  errors: (state) => state.errors,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
