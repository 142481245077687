<template>
  <div
    class="form-group"
    :class="[
      { 'has-success': success },
      { 'has-warning': warning },
      { 'has-danger': danger },
      { row: inline },
    ]"
  >
    <label
      v-if="isLabel"
      :for="id"
      :class="[{ 'col-sm-2 col-form-label': inline }]"
      :aria-label="id"
    >
      {{ label }}
    </label>
    <div :class="[{ 'col-sm-10': inline }]">
      <div class="input-group">
        <span v-if="addonVisibility.start" class="input-group-prepend">
          <span class="input-group-text">
            <slot name="addon-start"></slot>
          </span>
        </span>
        <input
          :id="id"
          ref="input"
          v-maska="maska"
          class="form-control"
          :type="type"
          :disabled="disabled"
          :class="[
            { 'form-control-sm': size === 'small' },
            { 'form-control-lg': size === 'large' },
            { 'form-control-success': success },
            { 'form-control-warning': warning },
            { 'form-control-danger': danger },
            { masked: masked },
          ]"
          :aria-describedby="id + 'Help'"
          :placeholder="placeholder"
          :value="value"
          :autocomplete="isAutocomplete"
          v-bind="$attrs"
          @input="onInput"
          @change="$emit('change', $event)"
        />
        <span v-if="addonVisibility.end" class="input-group-append">
          <span class="input-group-text">
            <slot name="addon-end"></slot>
          </span>
        </span>
      </div>
      <!-- States -->
      <div
        v-if="stateEnabled && success && !disabled"
        class="form-control-feedback"
      >
        {{ successMessage }}
      </div>
      <div
        v-if="stateEnabled && warning && !disabled"
        class="form-control-feedback"
      >
        {{ warningMessage }}
      </div>
      <div
        v-if="stateEnabled && danger && !disabled"
        class="form-control-feedback"
      >
        {{ dangerMessage }}
      </div>
      <!-- Description -->
      <small
        v-if="isDescription"
        :id="id + 'Help'"
        class="text-muted"
        :class="[{ 'form-text': !inlineDescription }]"
      >
        {{ description }}
      </small>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputBox",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: "",
    },
    maska: {
      type: [String, Array],
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
      validator: function (value) {
        return (
          [
            "text",
            "password",
            "datetime-local",
            "date",
            "month",
            "time",
            "week",
            "number",
            "email",
            "url",
            "search",
            "tel",
            "color",
          ].indexOf(value) > -1
        )
      },
    },
    addon: {
      type: String,
      default: "none",
      validator: function (value) {
        return ["none", "both", "start", "end"].indexOf(value) > -1
      },
    },
    // Text
    label: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: undefined,
    },
    // State
    success: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
      default: "",
    },
    warningMessage: {
      type: String,
      default: "",
    },
    dangerMessage: {
      type: String,
      default: "",
    },
    // Toggle Inline UI
    inline: {
      type: Boolean,
      defalt: false,
    },
    inlineDescription: {
      type: Boolean,
      default: false,
    },
    // Control size
    size: {
      type: String,
      default: "normal",
      validator: function (value) {
        return ["small", "normal", "large"].indexOf(value) > -1
      },
    },
    // Disable the control
    disabled: {
      type: Boolean,
      default: false,
    },
    // Browser autocomplete
    autocomplete: {
      type: Boolean,
      default: true,
    },
    masked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addonVisibility: {
        start: false,
        end: false,
      },
    }
  },
  computed: {
    isAutocomplete() {
      if (this.autocomplete === true) {
        return "on"
      } else {
        return "off"
      }
    },
    isDescription() {
      if (this.description === undefined) {
        return false
      } else {
        return true
      }
    },
    isLabel() {
      if (this.label === undefined) {
        return false
      } else {
        return true
      }
    },
    stateEnabled() {
      if (
        this.success === true ||
        this.warning === true ||
        this.danger === true
      ) {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    this.addonConfig()
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.value)
    },
    addonConfig() {
      if (this.addon === "none") {
        this.addonVisibility.start = false
        this.addonVisibility.end = false
      } else if (this.addon === "both") {
        this.addonVisibility.start = true
        this.addonVisibility.end = true
      } else if (this.addon === "start") {
        this.addonVisibility.start = true
        this.addonVisibility.end = false
      } else if (this.addon === "end") {
        this.addonVisibility.start = false
        this.addonVisibility.end = true
      }
    },
  },
}
</script>
