import Api from "./api"

export default {
  /**
   * Return list of vendors
   */
  fetch() {
    return Api().get("vendors/")
  },

  /**
   * Return a single vendors by id
   * @param {Number} id the vendors id
   */
  fetchOne(id) {
    return Api().get(`vendors/${id}/`)
  },

  /**
   * Send a post request to the vendors endpoint
   * @param {Object} payload a vendors object
   */
  create(payload) {
    return Api().post("vendors/", payload)
  },

  /**
   * Send a put request to the vendors endpoint
   * @param {Number} id the vendors id
   * @param {Object} payload a vendors object
   */
  update(id, payload) {
    return Api().put(`vendors/${id}/`, payload)
  },

  /**
   * Send a delete request to the vendors endpoint
   * @param {Number} id the vendors id
   */
  delete(id) {
    return Api().delete(`vendors/${id}/`)
  },
}
