<template>
  <nav
    class="navbar navbar-expand-sm navbar-light bg-cool-gray py-1"
    style="border-bottom: 1px solid #ccc"
    role="navigation"
  >
    <div class="container justify-content-start">
      <router-link to="/" class="navbar-brand">Renewit</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar-content"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbar-content" class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link active-class="active" class="nav-link" to="/purchases">
              Purchases
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              active-class="active"
              class="nav-link"
              to="/line-items"
            >
              Line Items
            </router-link>
          </li>
          <li class="nav-item">
            <router-link active-class="active" class="nav-link" to="/vendors">
              Vendors
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              active-class="active"
              class="nav-link"
              to="/purchase-types"
            >
              Purchase Types
            </router-link>
          </li>
        </ul>
      </div>
      <ul class="navbar-nav ml-auto">
        <li v-if="$auth.hasRole('auth_admin')" class="nav-item">
          <a class="nav-link" :href="authAdminUrl">User Admin</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click.prevent="$auth.logout()">
            Logout
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    authAdminUrl() {
      return `https://access.fso.arizona.edu/manage/${process.env.VUE_APP_COGNITO_CLIENT_ID}`
    },
  },
}
</script>

<style lang="postcss" scoped>
.nav-link {
  white-space: nowrap;
}
</style>
