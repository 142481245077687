<template>
  <div class="modal-wrapper">
    <transition
      name="modal"
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
      @after-leave="onAfterLeave"
    >
      <div
        v-show="isVisible"
        ref="modal"
        v-bind="$attrs"
        class="modal"
        :class="[
          { fade: !noFade },
          { show: isShow },
          { 'modal-visible': isModalVisible },
          { 'modal-dark': dark },
        ]"
        role="dialog"
        :aria-hidden="visible ? null : 'true'"
        aria-describedby="modal_content"
        aria-labelledby="modal_title"
        @click="onClickOut"
      >
        <div
          class="modal-dialog"
          :class="[sizeClass, { 'modal-dialog-centered': centered }]"
          :style="widthStyles"
          role="document"
        >
          <div class="modal-content" @click.stop @focusout="onFocusOut">
            <!-- Header -->
            <div v-if="!hideHeader" class="modal-header">
              <slot name="header">
                <h5 id="modal_title" class="modal-title">{{ title }}</h5>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  @click="hide"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </slot>
            </div>

            <!-- Content -->
            <div id="modal_content" class="modal-body">
              <slot></slot>
            </div>

            <!-- Footer -->
            <div v-if="!hideFooter" class="modal-footer">
              <slot name="footer">
                <!-- Default -->
                <button type="button" class="btn btn-primary" @click="hide">
                  Close
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- Backdrop -->
    <div
      v-if="!hideBackdrop"
      v-show="isVisible"
      class="modal-backdrop"
      :class="[{ fade: !noFade }, { show: isShow }]"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  inheritAttrs: false,
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      validator: function (value) {
        return ["custom", "large", "small", "normal"].indexOf(value) > -1
      },
      default: "normal",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    noFade: {
      type: Boolean,
      default: false,
    },
    disableBackdropClose: {
      type: Boolean,
      default: false,
    },
    disableEscClose: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideBackdrop: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 800,
    },
    minWidth: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      // State of property visibility
      isVisible: this.visible,
      // Indicate whether in a transition
      isTransitioning: false,
      // Manage `show` class
      isShow: false,
      // Manage `modal-visible` class
      isModalVisible: false,
    }
  },
  computed: {
    sizeClass() {
      if (this.size === "small") {
        return "modal-sm"
      } else if (this.size === "large") {
        return "modal-lg"
      } else if (this.size === "custom") {
        return "modal-custom"
      }
      return undefined
    },
    widthStyles() {
      if (this.size === "custom") {
        return `min-width: ${this.minWidth}px; max-width: ${this.maxWidth}px`
      } else {
        return ""
      }
    },
  },
  watch: {
    visible(val) {
      // Trigger the proper event
      val ? this.show() : this.hide()
    },
  },
  mounted() {
    if (this.isVisible) {
      this.show()
    }
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open")
  },
  methods: {
    show() {
      if (this.isVisible) {
        return
      }
      this.$nextTick(() => {
        this.isVisible = true
        this.$emit("change", true)
        window.addEventListener("keyup", this.onEsc)
      })
    },
    hide() {
      if (!this.isVisible) {
        return
      }
      this.isVisible = false
      this.$emit("change", false)
      window.removeEventListener("keyup", this.onEsc)
    },

    // Transition Handling

    onBeforeEnter() {
      this.isTransitioning = true
      document.body.classList.add("modal-open")
    },
    onEnter() {
      this.isModalVisible = true
      this.$refs.modal.scrollTop = 0
    },
    onAfterEnter() {
      this.isShow = true
      this.isTransitioning = false
      this.$nextTick(() => {
        this.$emit("shown")
      })
    },
    onBeforeLeave() {
      this.isTransitioning = true
    },
    onLeave() {
      this.isShow = false
    },
    onAfterLeave() {
      this.isModalVisible = false
      this.isTransitioning = false
      document.body.classList.remove("modal-open")
      this.$nextTick(() => {
        this.$emit("hidden")
      })
    },

    // Events

    // Hide modal when the backdrop is clicked
    onClickOut() {
      if (this.isVisible && !this.disableBackdropClose) {
        this.hide()
      }
    },
    // Hide modal when Esc is pressed
    onEsc(event) {
      if (this.isVisible && !this.disableEscClose && event.which === 27) {
        this.hide()
      }
    },
    onFocusOut(evt) {
      // If focus leaves modal content, bring it back
      const content = this.$refs.content
      if (this.isVisible && content && !content.contains(evt.relatedTarget)) {
        content.focus()
      }
    },
  },
}
</script>

<style lang="postcss">
.modal-visible {
  display: block !important;
}
.modal-title {
  margin-bottom: 0 !important;
  line-height: 1.5 !important;
}
.modal-body {
  margin: 0px;
}
.modal-custom {
  padding: 0px 15px;
  margin: 30px auto !important;
}
</style>
