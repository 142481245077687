<template>
  <div class="m-1 flex-bigly">
    <div
      class="d-flex flex-row justify-content-between align-items-center pb-1"
    >
      <div>
        <icon-button
          id="pt-create"
          class="mr-1"
          label="Create"
          type="primary"
          icon="cross"
          data-cy="createPurchaseType"
          @click="openCreateModal"
        />
        <icon-button
          id="ptEdit"
          label="Edit"
          icon="pencil"
          type="primary-light"
          :disabled="!selectionExists"
          data-cy="editPurchaseType"
          @click="openEditModal"
        />
      </div>
      <div class="d-flex flex-row">
        <input-box
          id="fuzzySearch"
          v-model="quickFilter"
          class="mb-0"
          type="search"
          placeholder="Search all..."
          addon="start"
          data-cy="fuzzySearch"
        >
          <icon slot="addon-start" icon="search"></icon>
        </input-box>
        <icon-button
          id="refreshButton"
          title="Refresh"
          class="ml-1 text-dark"
          type="secondary"
          outline
          icon="refresh-ccw"
          data-cy="refreshButton"
          @click="refreshGrid"
        />
      </div>
    </div>

    <grid-tools
      :filter-action="toggleFilterPanel"
      :filter-active="showFilters"
      :column-action="toggleColumnSelectPanel"
      :column-active="showColumnSelect"
      :grid-options="gridOptions"
      @refreshGrid="refreshGrid"
      @clearFilters="$refs.filters.clearAll()"
      @showDefaultColumns="showDefaultColumns"
      @showAllColumns="showAllColumns"
    />

    <div class="flex-bigly-row grid-wrapper">
      <filter-panel
        v-if="gridApi"
        v-show="showFilters"
        ref="filters"
        class="panel panel-filters"
        :row-data="items"
        :column-defs="gridOptions.columnDefs"
        :grid-api="gridApi"
      />
      <ag-grid-vue
        ref="grid"
        class="grid grid-flex ag-theme-balham no-status-bar"
        :row-data="items"
        :grid-options="gridOptions"
        @grid-ready="onGridReady"
        @row-double-clicked="openEditModal"
      />
      <columns-panel
        v-if="columnApi"
        v-show="showColumnSelect"
        ref="columns"
        class="panel panel-columns"
        :column-api="columnApi"
      />
    </div>

    <!-- Modal -->
    <modal
      id="purchaseTypeModal"
      ref="purchaseTypeModal"
      v-model="modalVisible"
      :title="modalTitle"
      role="dialog"
    >
      <template #default>
        <purchase-types-form v-if="modalVisible === true" id="form" />
      </template>
      <template slot="footer">
        <icon-button
          id="cancelForm"
          label="Cancel"
          type="accent"
          outline
          icon="x"
          data-cy="cancelPurchaseType"
          @click="closeModal"
        />
        <icon-button
          id="clearForm"
          label="Clear"
          type="warning"
          outline
          icon="wind"
          data-cy="clearPurchaseType"
          @click="clearForm"
        />
        <icon-button
          label="Save"
          type="primary"
          icon="save"
          :disabled="disableSave"
          data-cy="savePurchaseType"
          @click="modalSave"
        />
      </template>
    </modal>
  </div>
</template>

<script>
// Components
import PurchaseTypesForm from "@/components/PurchaseTypesForm.vue"
import GridTools from "@/components/GridTools.vue"
import { AgGridVue } from "ag-grid-vue"
import FilterPanel from "@/components/FilterPanel.vue"
import ColumnsPanel from "@/components/ColumnsPanel.vue"
import InputBox from "@/components/InputBox.vue"
import IconButton from "@/components/IconButton.vue"
import Icon from "@/components/Icon.vue"
import Modal from "@/components/Modal.vue"
// Helpers, Functionality
import { mapActions, mapGetters } from "vuex"
import useGrid from "@/composables/useGrid"

export default {
  components: {
    FilterPanel,
    ColumnsPanel,
    AgGridVue,
    GridTools,
    PurchaseTypesForm,
    InputBox,
    IconButton,
    Icon,
    Modal,
  },
  beforeRouteLeave(to, from, next) {
    this.clearItem()
    next()
  },
  setup(_props) {
    const gridConfig = {
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          hide: false,
          menuTabs: ["generalMenuTab"],
        },
        {
          headerName: "Active",
          field: "active",
          hide: false,
          menuTabs: ["generalMenuTab"],
          cellRenderer: "booleanCellRenderer",
        },
      ],
    }
    const gridDefaults = {
      sort: [{ colId: "name", sort: "asc" }],
      filter: {
        active: {
          filterType: "set",
          values: ["true"],
        },
      },
    }
    const {
      modal,
      gridApi,
      columnApi,
      gridOptions,
      quickFilter,
      showColumnSelect,
      showFilters,
      selectionExists,
      setGridDefaults,
      showDefaultColumns,
      showAllColumns,
      toggleFilterPanel,
      toggleColumnSelectPanel,
    } = useGrid(gridConfig, gridDefaults)
    return {
      modal,
      gridApi,
      columnApi,
      gridOptions,
      quickFilter,
      showColumnSelect,
      showFilters,
      selectionExists,
      setGridDefaults,
      showDefaultColumns,
      showAllColumns,
      toggleFilterPanel,
      toggleColumnSelectPanel,
    }
  },
  data() {
    return {
      disableSave: false,
      modalVisible: false,
    }
  },
  computed: {
    ...mapGetters("purchaseTypes", ["activeItem", "items", "errors"]),
    modalTitle: function () {
      if (this.modal.type === "create") {
        return "Create New Purchase Type"
      } else {
        return "Edit Purchase Type"
      }
    },
  },
  methods: {
    ...mapActions("purchaseTypes", [
      "put",
      "post",
      "setItem",
      "clearItem",
      "fetchAll",
    ]),
    openCreateModal() {
      this.clearItem()
      this.modalVisible = true
      this.modal.type = "create"
      this.modal.action = "post"
    },
    openEditModal() {
      this.modalVisible = true
      this.setItem(this.gridApi.getSelectedRows()[0])
      this.modal.type = "edit"
      this.modal.action = "put"
    },
    closeModal() {
      this.modal.type = ""
      this.modalVisible = false
    },
    async modalSave() {
      this.disableSave = true
      try {
        let response =
          this.modal.action === "post" ? await this.post() : await this.put()
        if (response !== null && !Object.keys(this.errors).length) {
          this.closeModal()
          this.refreshGrid()
        }
      } catch (error) {
        console.error("something went wrong on Save:")
        console.error(error)
      }
      this.disableSave = false
    },
    clearForm() {
      // Clear it
      this.clearItem()
    },
    async refreshGrid() {
      this.gridApi.showLoadingOverlay()
      try {
        await this.fetchAll()
        this.setGridDefaults()
        this.showDefaultColumns()
        this.$refs.filters.init()
      } catch (error) {
        console.error("something went wrong on Refresh: ")
        console.error(error)
      }
      this.gridApi.hideOverlay()
    },
    async onGridReady() {
      await this.$nextTick() // await existence of filters ref
      this.refreshGrid()
    },
  },
}
</script>
