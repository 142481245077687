import PurchasesService from "../services/PurchasesService"
import LineItemsService from "../services/LineItemsService"
import Uuid from "uuid"

const state = {
  items: [],
  activeItem: {
    date_renewed: "",
    expiration_date: "",
    edoc_number: "",
    fso_contact_name: "",
    fso_contact_unit: "",
    notes: "",
    purchase_date: "",
    purchase_description: "",
    purchase_total: "",
    status: "",
    vendor: "",
    lineitem_purchase: [],
  },
  errors: {
    lineitem_purchase: [],
  },
  showVendorForm: false,
  showPurchaseTypeForm: false,
}

const reset = {
  date_renewed: "",
  expiration_date: "",
  edoc_number: "",
  fso_contact_name: "",
  fso_contact_unit: "",
  notes: "",
  purchase_date: "",
  status: "",
  purchase_description: "",
  purchase_total: "",
  vendor: "",
}

const lineitemReset = {
  account: "",
  object_code: "",
  purchase_type: "",
  quantity: "",
  shipping_amount: "",
  tax: "",
  unit_cost: "",
  line_item_total: 0,
}

const mutations = {
  SET_ITEM(state, { ...fields }) {
    state.activeItem = {
      ...fields,
    }
  },
  SET_ITEMS(state, payload) {
    state.items = payload
  },
  CLEAR_ITEM(state) {
    // preserve ID and line items on clear
    const id = state.activeItem.id
    const lineitem_purchase = Array.from(state.activeItem.lineitem_purchase)
    if (lineitem_purchase.length === 0) {
      lineitem_purchase.push({
        uuid: Uuid(),
        ...lineitemReset,
      })
    }
    state.activeItem = {
      id,
      ...reset,
      lineitem_purchase,
    }
  },
  RESET_ACTIVE_ITEM(state) {
    state.activeItem = {
      ...reset,
      lineitem_purchase: [
        {
          ...lineitemReset,
        },
      ],
    }
  },
  SET_FIELD(state, { field, payload }) {
    state.activeItem[field] = payload
  },
  SET_LINEITEM_FIELD(state, { index, field, payload }) {
    state.activeItem.lineitem_purchase[index][field] = payload
  },
  SET_ERRORS(state, errors) {
    state.errors = errors
  },
  CLEAR_ERRORS(state) {
    state.errors = {
      lineitem_purchase: [],
    }
  },
  CREATE_LINE_ITEM(state) {
    state.activeItem.lineitem_purchase.push({
      // Create a Uuid
      uuid: Uuid(),
      ...lineitemReset,
    })
  },
  UPDATE_LINE_ITEM(state, payload, index) {
    state.activeItem.lineitem_purchase[index] = payload
  },
  DELETE_LINE_ITEM(state, index) {
    state.activeItem.lineitem_purchase.splice(index, 1)
  },
  CLEAR_LINE_ITEM(state, index) {
    // Preserve the id on clear
    const id = state.activeItem.lineitem_purchase[index].id
    // Preserve the uuid on clear, easier for form rendering
    const uuid = state.activeItem.lineitem_purchase[index].uuid
    // Loop through the object so that Vue can observe the changes
    // Vue can't see properties added to arrays due to limitations of javascript
    // See: https://vuejs.org/v2/guide/reactivity.html for an explanation
    Object.keys(state.activeItem.lineitem_purchase[index]).forEach((key) => {
      if (key === "id") {
        state.activeItem.lineitem_purchase[index][key] = id
      } else if (key === "uuid") {
        state.activeItem.lineitem_purchase[index][key] = uuid
      } else if (key === "line_item_total") {
        state.activeItem.lineitem_purchase[index][key] = 0
      } else {
        state.activeItem.lineitem_purchase[index][key] = ""
      }
    })
  },
  SET_VENDOR_FORM(state, payload) {
    state.showVendorForm = payload
  },
  SET_PURCHASE_TYPE_FORM(state, payload) {
    state.showPurchaseTypeForm = payload
  },
  SET_PURCHASE_TOTAL(state, payload) {
    state.activeItem.purchase_total = payload
  },
}

const actions = {
  setItem({ commit }, payload) {
    commit("SET_ITEM", payload)
    commit("CLEAR_ERRORS")
  },
  clearItem({ commit }) {
    commit("CLEAR_ITEM")
    commit("CLEAR_ERRORS")
  },
  clearErrors({ commit }) {
    commit("CLEAR_ERRORS")
  },
  resetActiveItem({ commit }) {
    commit("RESET_ACTIVE_ITEM")
    commit("CLEAR_ERRORS")
  },
  setItemField({ commit }, payload) {
    commit("SET_FIELD", payload)
  },
  setLIField({ commit }, payload) {
    commit("SET_LINEITEM_FIELD", payload)
  },
  addLI({ commit }) {
    commit("CREATE_LINE_ITEM")
  },
  removeLI({ commit }, index) {
    commit("DELETE_LINE_ITEM", index)
  },
  clearLI({ commit }, index) {
    commit("CLEAR_LINE_ITEM", index)
  },
  updateLI({ commit }, payload, index) {
    commit("UPDATE_LINE_ITEM", payload, index)
  },
  // Does not set anything in the store, returns the purchase, ready for use.
  // If you need to make it the activeItem, call setItem.
  // eslint-disable-next-line no-unused-vars
  async fetchOne({ commit }, id) {
    try {
      const response = await PurchasesService.fetchOne(id)
      const purchase = response.data
      // Add a UUID to each line item for use within the frontend
      purchase.lineitem_purchase.forEach((li) => {
        li.uuid = Uuid()
      })
      // Extract purchase types from the line items, removing duplicates
      purchase.purchase_types = purchase.lineitem_purchase
        .map((li) => {
          return li.purchase_type
        })
        .filter((li, index, self) => {
          // remove duplicates
          return self.indexOf(li) === index
        })
        .join(", ")
      return purchase
    } catch (error) {
      return "GET failed:" + error.status + " - " + error.statusText
    }
  },
  async fetchAll({ commit }) {
    try {
      const response = await PurchasesService.fetch()
      const purchases = response.data.map((purchase) => {
        // Add a UUID to each line item for use within the frontend
        purchase.lineitem_purchase.forEach((li) => {
          li.uuid = Uuid()
        })
        // Extract purchase types from the line items, removing duplicates
        purchase.purchase_types = purchase.lineitem_purchase
          .map((li) => {
            return li.purchase_type
          })
          .filter((li, index, self) => {
            // remove duplicates
            return self.indexOf(li) === index
          })
          .join(", ")
        return purchase
      })
      commit("SET_ITEMS", purchases)
    } catch (error) {
      return "GET failed:" + error.status + " - " + error.statusText
    }
  },
  async post({ commit, state }) {
    const postItem = processItem(state.activeItem)
    try {
      const response = await PurchasesService.create(postItem)
      commit("CLEAR_ERRORS")
      return response
    } catch (error) {
      console.log(error)
      handleErrs(commit, error)
      return null
    }
  },
  async put({ commit, state }) {
    const putItem = processItem(state.activeItem)
    try {
      const response = await PurchasesService.update(putItem.id, putItem)
      commit("CLEAR_ERRORS")
      return response
    } catch (error) {
      handleErrs(commit, error)
      return null
    }
  },
  // Hello future devs. Please note that this function deletes a line item at a specific index not a purchase
  async delete({ commit, state }, index) {
    const deleteItem = state.activeItem.lineitem_purchase[index].id
    try {
      const response = await LineItemsService.delete(deleteItem)
      commit("CLEAR_ERRORS")
      return response
    } catch (error) {
      handleErrs(commit, error)
      return null
    }
  },
  setPurchaseTotal({ commit }, payload) {
    commit("SET_PURCHASE_TOTAL", payload)
  },
  setShowVendorForm({ commit }, status) {
    commit("SET_VENDOR_FORM", status)
  },
  setShowPurchaseTypeForm({ commit }, status) {
    commit("SET_PURCHASE_TYPE_FORM", status)
  },
}

const getters = {
  items: (state) => state.items,
  activeItem: (state) => state.activeItem,
  errors: (state) => state.errors,
  activeLineItems: (state) => state.activeItem.lineitem_purchase,
  activeLineItemErrors: (state) => state.errors.lineitem_purchase,
  lineItems: (state) => {
    const result = []
    for (const purchase of state.items) {
      for (const lineItem of purchase.lineitem_purchase) {
        result.push(lineItem)
      }
    }
    return result
  },
  showVendorForm: (state) => state.showVendorForm,
  showPurchaseTypeForm: (state) => state.showPurchaseTypeForm,
}

function processItem(item) {
  // Send Date_renewed as null rather than an empty string
  if (item.date_renewed === "") {
    item.date_renewed = null
  }
  if (item.purchase_date === "") {
    item.purchase_date = null
  }
  if (item.expiration_date === "") {
    item.expiration_date = null
  }
  if (item.edoc_number === "") {
    item.edoc_number = null
  }
  return item
}

function handleErrs(commit, err) {
  if (err.response.status === 400) {
    const finalErr = {}
    finalErr.server =
      "Something's not right. Check the form for problems and try again."
    for (const key in err.response.data) {
      finalErr[key] = err.response.data[key].join("\n")
    }
    // every property is an array of strings except lineitem_purchase
    // which is an array of objects which each need to be processed
    finalErr.lineitem_purchase = []
    if (err.response.data.lineitem_purchase) {
      for (const li of err.response.data.lineitem_purchase) {
        const errs = {}
        for (const key in li) {
          errs[key] = li[key].join("\n")
        }
        finalErr.lineitem_purchase.push(errs)
      }
    }
    commit("SET_ERRORS", finalErr)
  } else {
    commit("SET_ERRORS", {
      server:
        "Uh oh! Server returned error status: " +
        err.response.status +
        " - " +
        err.response.statusText,
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
