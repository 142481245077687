<template>
  <div class="grid-tools__wrapper d-flex">
    <div class="grid-tools__left mr-auto">
      <icon-button
        type="primary"
        outline
        :active="filterActive"
        icon="filter"
        label="Filters"
        data-cy="filtersButton"
        @click="filterAction"
      />
    </div>
    <div class="ml-auto d-flex flex-row">
      <icon-button
        title="Scroll to Top"
        type="primary"
        outline
        icon="arrow-up"
        class="mr-1 ml-auto"
        @click="scrollToTop"
      />
      <icon-button
        type="primary"
        outline
        :active="columnActive"
        label="Columns"
        icon="columns"
        data-cy="columnsButton"
        @click="columnAction"
      />
      <div class="dropdown">
        <icon-button
          id="menuToggle"
          type="primary"
          outline
          icon="chevron-down"
          class="px-1 mx-1 h-100"
          aria-haspopup="true"
          aria-expanded="false"
          title="Grid tools"
          aria-label="Toggle grid tools menu"
          data-cy="toggleGridTools"
          @click="show = !show"
        />
        <div
          class="dropdown-menu dropdown-menu-right"
          :class="{ show: show }"
          aria-labelledby="menuToggle"
        >
          <h6 class="dropdown-header">Column Sizing and Visibility</h6>
          <icon-button
            type="secondary"
            class="dropdown-item"
            menu
            size="normal"
            data-cy="showAllButton"
            label="Show All"
            icon="eye"
            @click="showAllColumns"
          />
          <icon-button
            type="secondary"
            menu
            data-cy="showDefaultButton"
            class="dropdown-item"
            label="Show Default"
            icon="repeat"
            @click="showDefaultColumns"
          />
          <icon-button
            type="secondary"
            menu
            data-cy="resizeColumnsButton"
            class="dropdown-item"
            label="Size to Fit"
            icon="maximize"
            @click="sizeColumnsToFit"
          />
          <icon-button
            type="secondary"
            menu
            data-cy="autosizeColumnsButton"
            class="dropdown-item"
            label="Auto Size"
            icon="layout-grid"
            @click="sizeColumnsAuto"
          />

          <div class="dropdown-divider" />

          <h6 class="dropdown-header">Data Visibility</h6>
          <icon-button
            type="secondary"
            menu
            data-cy="clearFiltersButton"
            class="dropdown-item"
            label="Clear Filters"
            icon="filter"
            @click="clearFilters"
          />
          <icon-button
            type="secondary"
            menu
            data-cy="clearSortingButton"
            class="dropdown-item"
            label="Clear Sorting"
            icon="columns"
            @click="clearSort"
          />
          <icon-button
            type="secondary"
            menu
            data-cy="restoreDefaultButton"
            class="dropdown-item"
            label="Restore Default"
            icon="repeat"
            @click="restoreDefault"
          />

          <div class="dropdown-divider" />

          <h6 class="dropdown-header">Export</h6>
          <icon-button
            type="secondary"
            menu
            data-cy="exportButton"
            class="dropdown-item"
            label="Export to CSV"
            icon="file-x"
            @click="exportToCsv"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from "./IconButton"

export default {
  name: "GridTools",
  components: {
    IconButton,
  },
  props: {
    /**
     * Reference to the function that shows the filters panel.
     * @prop {Function}
     */
    filterAction: {
      type: Function,
      required: true,
    },
    /**
     * Reference to the function that shows the columns panel.
     * @prop {Function}
     */
    columnAction: {
      type: Function,
      required: true,
    },
    /**
     * Whether the filters panel is shown or not
     * @prop {Boolean}
     */
    filterActive: {
      type: Boolean,
      required: true,
    },
    /**
     * Whether the columns panel is shown or not
     * @prop {Boolean}
     */
    columnActive: {
      type: Boolean,
      required: true,
    },
    /**
     * Reference to the gridOptions object
     * @prop {Object}
     */
    gridOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { show: false }
  },
  methods: {
    /**
     * Scroll the grid to the top
     */
    scrollToTop() {
      this.gridOptions.api.ensureIndexVisible(0, "top")
    },
    /**
     * Show all columns in the grid
     */
    showAllColumns() {
      this.$emit("showAllColumns")
      this.show = false
    },
    /**
     * Show default columns for the current page in the grid
     */
    showDefaultColumns() {
      this.$emit("showDefaultColumns")
      this.show = false
    },
    /**
     * Size all columns automatically
     */
    sizeColumnsAuto() {
      this.gridOptions.columnApi.autoSizeAllColumns()
      this.show = false
    },
    /**
     * Size all columns to fit in the visible area of the grid
     */
    sizeColumnsToFit() {
      this.gridOptions.api.sizeColumnsToFit()
      this.show = false
    },
    /**
     * Clear all filters
     */
    clearFilters() {
      this.$emit("clearFilters")
      this.show = false
    },
    /**
     * Clear all sorting
     */
    clearSort() {
      this.gridOptions.api.setSortModel(null)
      this.show = false
    },
    /**
     * Restore default sorting, filters, and column visibility
     */
    restoreDefault() {
      // By refreshing the grid we set the grid to its defaults
      this.$emit("refreshGrid")
      this.show = false
    },
    /**
     * Export the currently visible grid data to csv
     */
    exportToCsv() {
      this.gridOptions.api.exportDataAsCsv()
      this.show = false
    },
  },
}
</script>

<style lang="postcss" scoped>
.grid-tools__wrapper {
  padding: 0.25em;
  background-color: #f7f7f9;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 0;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.grid-tools__wrapper div.dropdown-menu {
  z-index: 1;
  font-size: 1rem;
  color: #292b2c;
  width: 15rem;
  margin-top: 0.25rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.grid-tools__wrapper div.dropdown-menu button.dropdown-item {
  border-radius: 0;
}
.grid-tools__wrapper div.dropdown-menu button.dropdown-item:hover {
  background-color: #b3b3b3;
}
.grid-tools__wrapper div.dropdown-menu button.dropdown-item:focus,
.grid-tools__wrapper div.dropdown-menu button.dropdown-item:active {
  border: none;
  box-shadow: none;
  outline: 0;
}
</style>
