<template>
  <p>{{ humanize }}</p>
</template>

<script>
/**
 * A cell renderer for ag-grid to render currency values
 */
export default {
  name: "CurrencyCellRenderer",
  computed: {
    humanize() {
      if (this.params.value !== null) {
        return "$" + this.params.value
      } else {
        return ""
      }
    },
  },
}
</script>
